<template>
  <!--公告的项目列表 --> 
  <div class="project_list">
    <van-grid :column-num="2" :border="false" clickable>
      <van-grid-item v-for="(item,index) in list" :key="index" @click="toNoticeList(item)">
        <template #default>
          <div class="list_item">
            <div class="list_icon">
              <van-image :src="item.kv" fit="cover" />
            </div>
            <div class="list_text">
              {{item.name}}
            </div>
          </div>
        </template>
      </van-grid-item>
    </van-grid>
  </div>
</template>

<script>
import { Grid, GridItem,Toast } from 'vant';
import { getList }  from '@/api/api';
import {  mapState,mapMutations } from 'vuex'
export default {
  data(){
    return {
      list:[],
    }
  },
  computed: {
    ...mapState(['projectInfo'])
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations(['setProjectInfo']),
     async init(){
       await getList()
       .then(res => {
        console.log("项目列表",res)
        this.list=res
      }).catch(err=>{
        Toast(err.response.data.message)
      })
    },
    // 跳转到公告列表
    toNoticeList(item){
      console.log("选中项目",item)
      this.setProjectInfo(item)
      this.$router.push({
        path:"/notice/notice_list",
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.project_list{
  // background: pink;
  padding: 10px;
  ::v-deep .van-grid {
    // border-radius: 10px;
    border-radius: 6px;
    .van-grid-item{
      .van-grid-item__content{
        padding: 8px !important;
      }
    }
  }
  .list_item{
    // background: pink;
    height:40vw;
    width: 100%;
    // border-radius: 10px;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    
    .list_icon{
      height: 70%;
      // background: pink;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;

      .van-image{
        // border-radius: 10px 10px 0 0;
        overflow: hidden;
        height:100%;
        object-fit: cover;
      }
    }
    .list_text{
      // height: 30%;
      font-size: 14px;
      color: #292929;
      display: flex;
      align-items: center;
      justify-content: center;
      // background: pink;
    }
    .list_text_active{
      background-color: rgba(247, 247, 247, 1);;
    }
  }
  // padding: 10px;
}
</style>